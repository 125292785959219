/**
 * Format the date into DD MMM, YYYY
 * @param {date} dateString The date to be formatted
 * @return The formatted date into the form of DD MMM, YYYY. Example, 15 May, 2020
 */
export const formatDate = (dateString, short = false) => {
  const date = new Date(dateString);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const monthsShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const monthIndex = date.getMonth();
  const month = short ? monthsShort[monthIndex] : months[monthIndex];
  return date.getDate() + ' ' + month + ' ' + date.getFullYear();
};

/**
 * Format the date into DD MMM YYYY, HH:mm
 * @param {date} dateString The date to be formatted
 * @return The formatted date into the form of DD MMM YYYY, HH:mm. Example, 15 May 2020, 12:00
 */
export const formatDateAndTime = (dateString, short = false) => {
  const date = new Date(dateString);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const monthsShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const monthIndex = date.getMonth();
  const month = short ? monthsShort[monthIndex] : months[monthIndex];
  // return date.getDate() + ' ' + month + ' ' + date.getFullYear();
  return (
    `0${date.getDate()}`.slice(-2) +
    ' ' +
    month +
    ' ' +
    date.getFullYear() +
    ', ' +
    `0${date.getHours()}`.slice(-2) +
    ':' +
    `0${date.getMinutes()}`.slice(-2)
  );
};

/**
 * Format the date into DD MMM YYYY, HH:mm
 * @param {date} dateString The date to be formatted
 * @return The formatted date into the form of DD MMM YYYY, HH:mm. Example, 15 May 2020, 12:00
 */
export const formatDateAndTimeUTC = (dateString, short = false) => {
  const date = new Date(dateString);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];
  const monthsShort = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const monthIndex = date.getUTCMonth();
  const month = short ? monthsShort[monthIndex] : months[monthIndex];
  // return date.getDate() + ' ' + month + ' ' + date.getFullYear();
  return (
    `0${date.getUTCDate()}`.slice(-2) +
    ' ' +
    month +
    ' ' +
    date.getUTCFullYear() +
    ', ' +
    `0${date.getUTCHours()}`.slice(-2) +
    ':' +
    `0${date.getUTCMinutes()}`.slice(-2)
  );
};

export const calculateDateToCourse = (course) => {
  let diff =
    (Date.parse(new Date(course.ApplicationStartDate)) -
      Date.parse(new Date())) /
    1000;

  // clear countdown when date is reached
  if (diff <= 0) return false;

  const timeLeft = {
    years: 0,
    days: 0,
    hours: 0,
    min: 0,
    sec: 0
  };

  // calculate time difference between now and expected date
  if (diff >= 365.25 * 86400) {
    // 365.25 * 24 * 60 * 60
    timeLeft.years = Math.floor(diff / (365.25 * 86400));
    diff -= timeLeft.years * 365.25 * 86400;
  }
  if (diff >= 86400) {
    // 24 * 60 * 60
    timeLeft.days = Math.floor(diff / 86400);
    diff -= timeLeft.days * 86400;
  }
  if (diff >= 3600) {
    // 60 * 60
    timeLeft.hours = Math.floor(diff / 3600);
    diff -= timeLeft.hours * 3600;
  }
  if (diff >= 60) {
    timeLeft.min = Math.floor(diff / 60);
    diff -= timeLeft.min * 60;
  }
  timeLeft.sec = diff;

  return timeLeft;
};

export const isVacancyBelowThreshold = (
  maxRegistration,
  vacancy,
  thresholdPercent = 20
) => {
  const thresholdCount = parseInt(
    Math.floor(thresholdPercent * maxRegistration) / 100
  );
  return vacancy < thresholdCount;
};

export const getScheduledInStrFromCountdown = (countdown) => {
  const { years, days, hrs, mins, secs } = countdown || {};

  if (years) {
    const plurifiedYearsLabel = years > 1 ? 'Years' : 'Year';
    return `${years} ${plurifiedYearsLabel}`;
  }

  if (days) {
    const plurifiedDaysLabel = days > 1 ? 'Days' : 'Day';
    return `${days} ${plurifiedDaysLabel}`;
  }

  if (hrs) {
    const plurifiedHrsLabel = hrs > 1 ? 'Hours' : 'Hour';
    return `${hrs} ${plurifiedHrsLabel}`;
  }

  if (mins) {
    const plurifiedMinsLabel = mins > 1 ? 'Minutes' : 'Minute';
    return `${mins} ${plurifiedMinsLabel}`;
  }

  if (secs) {
    const plurifiedSecsLabel = secs > 1 ? 'Seconds' : 'Second';
    return `${secs} ${plurifiedSecsLabel}`;
  }

  return '';
};
