import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getScheduledInStrFromCountdown } from '../utility/courseHelper';

const initialCountdownValue = {
  years: 0,
  days: 0,
  hrs: 0,
  mins: 0,
  secs: 0
};

const calculateCountdown = (endDate, reset) => {
  if (!endDate) {
    return initialCountdownValue;
  }

  let diff =
    (Date.parse(new Date(endDate)) - Date.parse(new Date())) / 1000;

  // clear countdown when date is reached
  if (diff <= 0) {
    reset?.();
    return initialCountdownValue;
  }

  const countdown = {
    years: 0,
    days: 0,
    hrs: 0,
    mins: 0,
    secs: 0
  };

  const SECONDS_IN_MIN = 60;
  const SECONDS_IN_HR = 60 * 60;
  const SECONDS_IN_DAY = 24 * SECONDS_IN_HR;
  const SECONDS_IN_YEAR = 365.25 * SECONDS_IN_DAY;

  // calculate time difference between now and expected date
  if (diff >= SECONDS_IN_YEAR) {
    countdown.years = Math.floor(diff / SECONDS_IN_YEAR);
    diff -= countdown.years * SECONDS_IN_YEAR;
  }
  if (diff >= SECONDS_IN_DAY) {
    countdown.days = Math.floor(diff / SECONDS_IN_DAY);
    diff -= countdown.days * SECONDS_IN_DAY;
  }
  if (diff >= SECONDS_IN_HR) {
    countdown.hrs = Math.floor(diff / SECONDS_IN_HR);
    diff -= countdown.hrs * SECONDS_IN_HR;
  }
  if (diff >= SECONDS_IN_MIN) {
    countdown.mins = Math.floor(diff / SECONDS_IN_MIN);
    diff -= countdown.mins * SECONDS_IN_MIN;
  }
  countdown.secs = isNaN(diff) ? 0 : diff;
  return countdown;
};

const useCountdown = (initialEndDate) => {
  const [countdown, setCountdown] = useState(
    calculateCountdown(new Date(initialEndDate))
  );
  const [hasEnded, setHasEnded] = useState(
    !initialEndDate || new Date(initialEndDate) < new Date()
  );

  const intervalId = useRef(null);

  const clearIntervalId = () => {
    clearInterval(intervalId.current);
  };

  // Instead of updating scheduledInStr every sec, only recalculate once every min. Hence countdown.min in dependency array
  const scheduledInStr = useMemo(() => {
    return getScheduledInStrFromCountdown(countdown);
  }, [countdown?.mins]); // eslint-disable-line react-hooks/exhaustive-deps

  const reset = useCallback(() => {
    setCountdown(initialCountdownValue);
    clearIntervalId();
    setHasEnded(true);
  }, []);

  useEffect(() => {
    if (initialEndDate && new Date(initialEndDate) > new Date()) {
      const calc = () => {
        setCountdown(calculateCountdown(initialEndDate, reset));
      };
      calc();

      intervalId.current = setInterval(calc, 1000); // every 1 sec
    } else {
      clearIntervalId();
    }

    return () => clearIntervalId();
  }, [initialEndDate]);

  return {
    countdown,
    scheduledInStr,
    hasEnded
  };
};

export default useCountdown;
