import { useAuthContext } from '@/contexts/AuthContext';
import { getIsFullNameSetInCommAppln } from '@/utility/memberFullNameSetUtil';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
const CompleteYourProfileModal = dynamic(
  () => import('./CompleteYourProfileModal')
);

const CompleteYourProfileModalWrapper = ({
  onProfileUpdateComplete = null
}) => {
  const { user } = useAuthContext();

  const [showModal, setShowModal] = useState(false);

  const isFullNameSetInCommAppln = getIsFullNameSetInCommAppln();
  const isProfileComplete =
    user?.learner?.firstName ||
    user?.learner?.lastName ||
    isFullNameSetInCommAppln;

  const closeModal = () => {
    setShowModal(false);
    if (onProfileUpdateComplete) {
      onProfileUpdateComplete();
    }
  };

  useEffect(() => {
    if (user && !isProfileComplete) setShowModal(true);
  }, [user, isProfileComplete]);

  return showModal ? (
    <CompleteYourProfileModal open={showModal} onClose={closeModal} />
  ) : null;
};

export default CompleteYourProfileModalWrapper;
