import {
  getShortCodeByLocale,
  getSupportedLocaleOptions,
  setLocale,
  t
} from '@/utility/localization';
import React from 'react';
import NasIOLogoWithText from './IconsV2/NasIOLogoWithText';
import Link from 'next/link';
import HeadlessDropdown from './Form/HeadlessDropdown/HeadlessDropdown';
import Icon from '@npl/Icon';
import { usePathname, useRouter } from 'next/navigation';
import useLocale from '@/hooks/useLocale';
import { useAuthContext } from '@/contexts/AuthContext';
import { openInNewTab } from '@/utility/helpers';
import NPLLinkButton, { LINK_BTN_HIERARCHY } from '../npl/NPLLinkButton';
import { trackGAEvent } from '@/utility/analytics';
import {
  CONTACT_SUPPORT_FROM_PUBLIC_PAGE_FOOTER,
  CREATE_COMMUNITY_FROM_PUBLIC_PAGE_FOOTER,
  FOOTER_ITEM_CLICK_EVENT
} from '@/utility/analyticsConsts';
import NPLButton from '../npl/NPLButton';
import { START_COMMUNITY_ONBOARDING_ROUTE } from '@/utility/routesHelper';
import { openMemberSupportPage } from '@/utility/freshdeskUtils';

const className = 'c-PublicPageFooter';

const PublicPageFooter = ({
  isCommunityAdmin,
  communitySlug,
  templateContentType,
  ctaButtonText = t('create-your-community')
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const { currentLocale } = useLocale();
  const { isLoggedIn, updateUserLocale } = useAuthContext();
  const displayedLocale = getShortCodeByLocale(currentLocale);

  return (
    <div className={`${className} mb-[160px] space-y-16 py-40`}>
      <div className="flex flex-col items-center">
        <span className="mb-4 text-label-sm font-medium text-npl-text-icon-on-light-surface-secondary">
          {t('built-with')}
        </span>
        <Link
          href={`/${
            currentLocale !== router.defaultLocale ? currentLocale : ''
          }`}
          onClick={() =>
            trackGAEvent(FOOTER_ITEM_CLICK_EVENT, {
              pageType: templateContentType,
              url: pathname
            })
          }>
          <NasIOLogoWithText />
        </Link>
      </div>
      <div className="flex w-auto items-center justify-center gap-16">
        {!isCommunityAdmin && (
          <div className="my-16 flex justify-center text-npl-text-icon-on-light-surface-tertiary">
            <NPLLinkButton
              hierarchy={LINK_BTN_HIERARCHY.SECONDARY}
              buttonText={t('contact-support')}
              size="xs"
              onClick={() => {
                trackGAEvent(CONTACT_SUPPORT_FROM_PUBLIC_PAGE_FOOTER, {
                  communitySlug: communitySlug
                });
                openMemberSupportPage();
              }}
            />
          </div>
        )}
        <HeadlessDropdown
          placement="bottom"
          renderField={() => (
            <div className="flex cursor-pointer items-center justify-center space-x-4 rounded-16 border-1 border-npl-neutral-light-solid-8 px-12 py-4">
              <Icon
                name="globe-02"
                width={16}
                height={16}
                className="fill-npl-text-icon-on-light-surface-secondary"
              />
              <span className="text-button-md font-medium uppercase text-npl-text-icon-on-light-surface-secondary">
                {displayedLocale}
              </span>
            </div>
          )}
          renderOptions={({ closeDropdown }) => {
            return (
              <ul className="flex min-w-[180px] flex-col rounded-12 bg-npl-base-white p-8 shadow-npl-styles-shadow-02">
                {getSupportedLocaleOptions().map((option) => (
                  // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                  <li
                    key={option.value}
                    className="flex cursor-pointer flex-row items-center rounded-12 px-8 py-16 hover:bg-npl-neutral-light-solid-3"
                    value={option.value}
                    onClick={async () => {
                      closeDropdown();
                      if (isLoggedIn) {
                        await updateUserLocale(option.value);
                      }
                      setLocale(option.value, true);
                    }}>
                    <span className="text-label-md font-medium text-npl-text-icon-on-light-surface-primary">
                      {option.label}
                    </span>
                    {currentLocale === option.value && (
                      <Icon
                        name="check"
                        width={16}
                        height={16}
                        className="ml-auto"></Icon>
                    )}
                  </li>
                ))}
              </ul>
            );
          }}
        />
      </div>
      <div className="flex justify-center">
        <NPLButton
          hierarchy="outline"
          buttonText={ctaButtonText}
          multiply
          onClick={() => {
            trackGAEvent(CREATE_COMMUNITY_FROM_PUBLIC_PAGE_FOOTER, {
              communitySlug: communitySlug
            });
            openInNewTab(START_COMMUNITY_ONBOARDING_ROUTE);
          }}
        />
      </div>
    </div>
  );
};

export default PublicPageFooter;
