import React, { FC, ReactNode } from 'react';
import { CommunityInfoType } from '@/features/Challenges/types';
import AuthNavBar from './AuthNavbar';
import { useAuthContext } from '@/contexts/AuthContext';
import useUserCommunitiesData from '@/hooks/useUserCommunitiesData';

type AuthNavbarPageLayoutProps = {
  communityInfo: CommunityInfoType;
  children: ReactNode | ((userCommunitiesData: unknown) => ReactNode);
};

const AuthNavbarPageLayout: FC<AuthNavbarPageLayoutProps> = ({
  communityInfo,
  children
}) => {
  const { isLoggedIn } = useAuthContext();

  const userCommunitiesData = useUserCommunitiesData({ communityInfo });

  const {
    userCommunities,
    isPendingApproval,
    isCommunityAdmin,
    isCommunityMember,
    openSignUpModal
  } = userCommunitiesData;

  return (
    <div>
      {isLoggedIn && (
        <div className="top-0 z-over-intercom flex h-64 w-full items-center px-12">
          <div className="flex-grow">
            <AuthNavBar
              communityInfo={communityInfo}
              userCommunities={userCommunities}
              isCommunityAdmin={isCommunityAdmin}
              isCommunityMember={isCommunityMember}
              isPendingApproval={isPendingApproval}
              openSignUpModal={openSignUpModal}
            />
          </div>
        </div>
      )}
      {typeof children === 'function'
        ? children(userCommunitiesData)
        : children}
    </div>
  );
};

export default AuthNavbarPageLayout;
